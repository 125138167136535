import React, { useEffect, useState } from 'react';
import TemplateDashboard from '../../components/TemplateDashboard';
import api from '../../services/api';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { formatDateStrToDDMMYYYYStr } from '../../utils/dateTime';
import { getEditionTypeFromEnum } from '../../utils/editions';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';

const style = {
  root:{
    overflowY: 'auto',
    padding: '20px'
  },
  listItem: {
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'start',
    marginBottom: '15px',
    backgroundColor: 'white',
    borderRadius: '5px'
  },
  itemThumbnail: {
    maxWidth: '130px',
    marginRight: '30px'
  },
  itemDetails: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'start',
    lineHeight: '1.3em'
  }
}

function Editions(){

  const [editions, setEditions] = useState([]);
  
  const MySwal = withReactContent(Swal);  

  useEffect(() => {
    async function getEditions(){ 
      try{
        const response = await api.get('/editions');
        setEditions(response.data);   
      }
      catch(error){ }
    }

    getEditions();
  }, []);

  function handleDeletePublication(editionId, editionTitle){
    MySwal.fire({
      icon: 'warning',
      title: <p>Deletar publicação</p>,
      text: `Você irá deletar: ${editionTitle}. Continuar?`,      
      showConfirmButton: true,
      confirmButtonText: 'Deletar',
      confirmButtonColor: '#d33', 
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then((willDelete) => {
      if(willDelete.value) {        
        deletePublication(editionId);
      } else {
        return MySwal.fire({     
          icon: 'success',     
          title: <p>Publicação não foi deletada</p>,          
          timer: 2000,
          showConfirmButton: false
      });
      }
    });
  }

  function removeItemFromList(editionId){  
    const newList = editions.filter((item) => editionId !== item.id);
    setEditions(newList);
  }

  async function deletePublication(editionId){
    MySwal.fire({
      title: <p>Deletando publicação</p>,          
      onOpen: () => {
        MySwal.showLoading();
      }
    });

    try{
      await api.delete(`/editions/${editionId}`);     
      MySwal.close();
      MySwal.fire({
        icon: 'success',
        title: <p>Publicação deletada</p>,          
        timer: 2000,
        showConfirmButton: false
      }); 
      removeItemFromList(editionId);
    }
    catch(error){
      MySwal.close();
      MySwal.fire({
        icon: 'error',
        title: <p>Não foi possível deletar a publicação</p>,                  
        showConfirmButton: true
      });
    }    
  }

  return(
    <TemplateDashboard>
      <div style={style.root}>
        <h1>Edições</h1>
        {editions.length ? null : <span>Não há publicações</span>}
        <List component="nav">
          {editions.map((edition, index) => (
              <ListItem key={edition.id} button style={style.listItem}> 
                <img src={edition.thumbnailUrl} style={style.itemThumbnail} alt="Capa jornal"/>
                
                <div style={style.itemDetails}>
                  <span><b>{edition.title}</b></span>
                  <span>Data de publicação: {formatDateStrToDDMMYYYYStr(edition.publicationDate)}</span>
                  <span>Tipo: {getEditionTypeFromEnum(edition.type)}</span>
                  <span>Arquivo: <a href={edition.fileUrl} target="_ablank">link</a></span>
                  <span>Thumbnail: <a href={edition.thumbnailUrl} target="_ablank">link</a></span>
                  <span>Id: {edition.id}</span>                  
                  
                  <Button
                    startIcon={<DeleteIcon />}
                    variant="outlined" 
                    color="secondary" 
                    style={{marginTop: '15px'}}
                    onClick={() => handleDeletePublication(edition.id, edition.title)}>
                    Deletar
                  </Button>
                </div>
              </ListItem> 
            )      
          )}     
        </List>
      </div>
    </TemplateDashboard>    
  );
}

export default Editions;