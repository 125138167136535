import React, { useEffect, useState } from 'react';
import TemplateDashboard from '../../components/TemplateDashboard';
import api from '../../services/api';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const style = {
  root:{
    overflowY: 'auto',
    padding: '20px'
  },
  container:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  card:{
    width: '20%'
  }
}

function Home(){

  const [statistics, setStatistics] = useState({
    totalEditions: 0,
    editions: 0,
    classifieds: 0
  });

  useEffect(() => {
    async function getEditionsStatistics(){ 
      try{
        const response = await api.get('/editions/statistics');
        setStatistics({
          totalEditions: response.data.totalEditions,
          editions: response.data.editions,
          classifieds: response.data.classifieds
        });   
      }
      catch(error){ }
    }

    getEditionsStatistics();
  }, []);


  return(
    <TemplateDashboard>
      <div style={style.root}>
        <h1>Home</h1>
        <div style={style.container}>
          <Card style={style.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Total de publicações
              </Typography>
              <Typography variant="h5" component="h2">
                {statistics.totalEditions}
              </Typography>
            </CardContent>
          </Card>
          <Card style={style.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Edições
              </Typography>
              <Typography variant="h5" component="h2">
                {statistics.editions}
              </Typography>
            </CardContent>
          </Card>
          <Card style={style.card}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Classificados
              </Typography>
              <Typography variant="h5" component="h2">
                {statistics.classifieds}
              </Typography>
            </CardContent>
          </Card>
        </div>
        
      </div>
    </TemplateDashboard>    
  );
}

export default Home;