import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

api.interceptors.request.use(request => {
  if(!request.url.includes('login')){
    const token = localStorage.getItem('jncAuthToken');
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});

api.interceptors.response.use(response => {
  return response;
}, error => { 
  if (!error.response.request.responseURL.includes('api/v1/auth') && (error.response?.status === 401 || error.response?.status === 403)) {  
    alert("Não autorizado, faça login novamente");
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error.message);
});

export default api;