import React, { useState } from 'react';
import TemplateDashboard from '../../components/TemplateDashboard';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from '../../services/api';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import Button from '@material-ui/core/Button';

const style = {
  root:{
    overflowY: 'auto',
    padding: '20px',
    display: 'flex',
    flexFlow: 'column'
  },
  form:{
    display: 'flex',
    flexFlow: 'column',
    width: '50%'
  },
  formItem:{
    marginBottom: 20
  },
  formGroup:{
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginBottom: 20
  },
  formGroupItem:{
    marginLeft: '20px'
  },
  emojiPicker: {
    position: "absolute",
    bottom: 10,
    right: 10,
    cssFloat: "right"
  }
}

function SendNotification(){
  
  const MySwal = withReactContent(Swal);  

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [values, setValues] = useState({
    title: '',
    message: '',
    tokens: []    
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleNotification(){

    var validValue={
      valid: true,
      message: ''
    };    

    if(values.title === null){
      validValue={
        valid: false,
        message: 'Título não pode ser vazio'
      };
    }

    if(values.title.lenght > 65){
      validValue={
        valid: false,
        message: 'Título não pode ser maior que 65 caracteres'
      };
    }

    if(values.message === ''){
      validValue={
        valid: false,
        message: 'Mensagem não pode ser vazia'
      };
    }

    if(values.message.lenght > 140){
      validValue={
        valid: false,
        message: 'Mensagem não pode ser maior que 140 caracteres'
      };
    }

    if(validValue.valid === false){
      return MySwal.fire({
        icon: 'error',
        title: <p>Erro</p>,
        text: `${validValue.message}`,      
        timer: 2000,
        showConfirmButton: true
      });
    }

    MySwal.fire({
      icon: 'warning',
      title: <p>Enviar notificação</p>,
      html: `<b>${values.title}</b>
             <br>${values.message}             
             <br><br>Continuar?`,      
      showConfirmButton: true,
      confirmButtonText: 'Enviar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then((willSend) => {
      if(willSend.value) {
        sendNotification();
      } else {
        return MySwal.fire({     
          icon: 'success',     
          title: <p>Notificação não enviada</p>,          
          timer: 2000,
          showConfirmButton: false
      });
      }
    });
  }

  async function sendNotification(){
    MySwal.fire({
      title: <p>Enviando notificação</p>,          
      onOpen: () => {
        MySwal.showLoading();
      }
    });

    try{
      await api.post(`/notifications/send`, values);  
         
      MySwal.close();
      MySwal.fire({
        icon: 'success',
        title: <p>Notificação enviada!</p>,          
        timer: 2000,
        showConfirmButton: false
      });       
    }
    catch(error){
      MySwal.close();
      MySwal.fire({
        icon: 'error',
        title: <p>Não foi possível enviar a notificação</p>,                  
        showConfirmButton: true
      });
    }    
  }

  const addEmoji = e => {
    let emoji = e.native;
    setValues({ ...values, title: values.title + emoji });
  }

  return(
    <TemplateDashboard>
      <div style={style.root}>
        <h1>Enviar Notificação</h1>
        <div style={style.form}>

          <div style={style.formGroup}>
            <FormControl variant="outlined">
              <InputLabel htmlFor="input-title">Título</InputLabel>
              <OutlinedInput 
                id="input-title" 
                label="Título" 
                value={values.title}  
                onChange={handleChange('title')}
              />
            </FormControl>

            <div style={style.formGroupItem}>            
              <span onClick={() => setShowEmojiPicker(true)} style={{fontSize: 24}}>🙂</span>
              { showEmojiPicker && <Picker style={style.emojiPicker} onSelect={addEmoji}/> }
            </div>
          </div>

          <FormControl variant="outlined" style={style.formItem}>
            <InputLabel htmlFor="input-message">Mensagem</InputLabel>
            <OutlinedInput 
              id="input-message" 
              label="Mensagem" 
              value={values.message}  
              onChange={handleChange('message')}
            />
          </FormControl>

          <Button variant="contained" color="primary" onClick={() => {handleNotification()}}>              
            Enviar
          </Button>
        </div>
      </div>
    </TemplateDashboard>
  );
}

export default SendNotification;