import React, { useState } from 'react';
import TemplateDashboard from '../../components/TemplateDashboard';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import api from '../../services/api';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import { formatDateObjToYYYYMMDDStr } from '../../utils/dateTime';
import { getEditionTypeFromEnum } from '../../utils/editions';

const style = {
  root:{
    overflowY: 'auto',
    padding: '20px',
    display: 'flex',
    flexFlow: 'column'
  },
  form:{
    display: 'flex',
    flexFlow: 'column',
    width: '50%'
  },
  formItem:{
    marginBottom: '20px'
  },
  formGroup:{
    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
    marginBottom: '20px'
  },
  formGroupItem:{
    marginLeft: '20px'
  },
  radioButton:{
    backgroundColor: 'blue',
  }
}

function CreateEdition(){
  
  const MySwal = withReactContent(Swal);  

  const [values, setValues] = useState({
    title: '',
    publicationDate: new Date(),
    type: 'edition',
    file: null,
    filename: 'Faça o upload do arquivo'
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  function handleDate(date){    
    setValues({ ...values, publicationDate: date });
  }

  function handleType(type){
    setValues({ ...values, type: type });
  }

  const handleFile = event =>{  
    setValues({ ...values, file: event.target.files[0], filename: event.target.files[0].name });
  }

  function handlePublication(){

    var validValue={
      valid: true,
      message: ''
    };    

    if(values.file === null){
      validValue={
        valid: false,
        message: 'Arquivo não pode ser vazio'
      };
    }

    if(values.title === ''){
      validValue={
        valid: false,
        message: 'Título não pode ser vazio'
      };
    }

    if(validValue.valid === false){
      return MySwal.fire({
        icon: 'error',
        title: <p>Erro</p>,
        text: `${validValue.message}`,      
        timer: 2000,
        showConfirmButton: true
      });
    }

    MySwal.fire({
      icon: 'warning',
      title: <p>Publicar edição</p>,
      html: `<b>${values.title}</b>
             <br>Data de publicação: ${values.publicationDate}
             <br>Tipo: ${getEditionTypeFromEnum(values.type)}
             <br> Arquivo: ${values.filename}
             <br><br>Continuar?`,      
      showConfirmButton: true,
      confirmButtonText: 'Publicar',
      showCancelButton: true,
      cancelButtonText: 'Cancelar'
    }).then((willPublish) => {
      if(willPublish.value) {
        publishEdition();
      } else {
        return MySwal.fire({     
          icon: 'success',     
          title: <p>Edição não foi publicada</p>,          
          timer: 2000,
          showConfirmButton: false
      });
      }
    });
  }

  async function publishEdition(){
    MySwal.fire({
      title: <p>Publicando edição</p>,          
      onOpen: () => {
        MySwal.showLoading();
      }
    });

    try{
      const data = new FormData();
      data.append('file', values.file);
      data.append('edition', JSON.stringify({
        title: values.title,
        publicationDate: formatDateObjToYYYYMMDDStr(values.publicationDate),
        type: values.type,
        enabled: true
      }));

      await api.post(`/editions`, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });  
         
      MySwal.close();
      MySwal.fire({
        icon: 'success',
        title: <p>Edição publicada!</p>,          
        timer: 2000,
        showConfirmButton: false
      });       
    }
    catch(error){
      MySwal.close();
      MySwal.fire({
        icon: 'error',
        title: <p>Não foi possível publicar a edição</p>,                  
        showConfirmButton: true
      });
    }    
  }

  return(
    <TemplateDashboard>
      <div style={style.root}>
        <h1>Publicar edição</h1>
        <div style={style.form}>
          <FormControl variant="outlined" style={style.formItem}>
            <InputLabel htmlFor="input-title">Título</InputLabel>
            <OutlinedInput 
              id="input-title" 
              label="Título" 
              value={values.title}  
              onChange={handleChange('title')}
            />
          </FormControl>

          <div style={style.formGroup}>
            <FormControl variant="outlined" style={{width: '70%'}}>              
              <InputLabel htmlFor="input-file">Arquivo</InputLabel>
              <OutlinedInput 
                id="input-file" 
                label="Arquivo"  
                value={values.filename}
                disabled             
              />
            </FormControl>

            <label htmlFor="contained-button-file" style={style.formGroupItem}>
              <input style={{display: 'none'}} accept="application/pdf" id="contained-button-file" multiple type="file" onChange={handleFile}/>
              <Button variant="outlined" component="span" color="primary">
                Escolher arquivo
              </Button>
            </label>
          </div>

          <div style={style.formGroup}>
            <FormControl style={{width: '30%'}}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Data Publicação"
                  format="dd/MM/yyyy"
                  value={values.publicationDate}
                  InputAdornmentProps={{ position: "end" }}
                  onChange={date => handleDate(date)}                  
                />
              </MuiPickersUtilsProvider>
            </FormControl>

            <FormControl component="fieldset" style={style.formGroupItem}>
              <FormLabel component="legend">Tipo</FormLabel>
              <RadioGroup row aria-label="type" name="row-radio-buttons-type" defaultValue="edition">
                <FormControlLabel value="edition" control={<Radio color="primary"/>} label="Edição" onChange={() => handleType('edition')}/>
                <FormControlLabel value="classified" control={<Radio color="primary"/>} label="Classificado" onChange={() => handleType('classified')}/>              
              </RadioGroup>
            </FormControl>  
          </div>      

          <Button variant="contained" color="primary" onClick={() => {handlePublication()}}>              
            Publicar
          </Button>
        </div>
      </div>
    </TemplateDashboard>
  );
}

export default CreateEdition;