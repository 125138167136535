import React, { useEffect }  from 'react';
import { useHistory } from 'react-router-dom';
import { isUserLoggedIn } from '../../utils/login';
import ListIcon from '@material-ui/icons/List';
import PublishIcon from '@material-ui/icons/Publish';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

import './index.css';

const style = {
  root: {
    height: '100vh',
    width: '100vw'
  },
  panel:{
    display: 'flex',
    flexFlow: 'row',
    width: '100%',
    height: '100%'
  },
  sideBar:{
    display: 'flex',
    flexFlow: 'column',
    fontSize: '14px',
    fontWeight: '200',
    fontFamily: 'sans-serif',
    backgroundColor: '#005A96',
    color: 'white',
    width: '210px'
  },
  sideBarLogo:{
    fontSize: '18px',
    fontWeight: 'bold',
    padding: '20px 5px 20px 5px',
    textAlign: 'center'
  },
  sideBarItem:{
  },
  sideBarMenu:{
    display: 'flex',
    flexFlow: 'column',
    cursor: 'pointer',
    marginBottom: '25px',
    marginLeft: '18px',    
  },
  sideBarMenuItem:{
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginTop: '10px',
    marginLeft: '25px',    
  },
  sideBarMenuIcon:{
    marginRight: '5px'
  },
  defaultCursor:{
    cursor: 'default'
  },
  content:{
    width: 'calc(100% - 210px)',
    height: '100%',
    backgroundColor: '#EEEEEE'
  }
};

function TemplateDashboard(props){

  const history = useHistory();

  useEffect(() => {
    if(!isUserLoggedIn()){
      history.push('/login');
    }
  }); 

  function handleLogout(){
    localStorage.removeItem('jncAuthToken');
    history.push('/login');
  }

  return(
    <div style={style.root}>
      <div style={style.panel}>        
        
        <div style={style.sideBar}>
          <div style={style.sideBarLogo}>Jornal Nossa Cidade</div>          

          <span className="side-bar-item" style={style.sideBarMenu} onClick={() => history.push('/')}>Home</span> 
          
          <div style={style.sideBarMenu}>                     
            <span style={style.defaultCursor}>Edições</span>
            <span className="side-bar-item" style={style.sideBarMenuItem} onClick={() => history.push('/editions')}><ListIcon style={style.sideBarMenuIcon}/>Todas</span>
            <span className="side-bar-item" style={style.sideBarMenuItem} onClick={() => history.push('/createedition')}><PublishIcon style={style.sideBarMenuIcon}/>Publicar</span>      
          </div>

          <div style={style.sideBarMenu}>                     
            <span style={style.defaultCursor}>Notificações</span>
            <span className="side-bar-item" style={style.sideBarMenuItem} onClick={() => history.push('/sendnotification')}><SendRoundedIcon style={style.sideBarMenuIcon}/>Enviar</span>            
          </div>

          <span className="side-bar-item" style={style.sideBarMenu} onClick={handleLogout}>Logout</span>

        </div>

        <div style={style.content}>
          {props.children}
        </div>

      </div>
    </div>
  );

}

export default TemplateDashboard;