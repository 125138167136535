import React, { useState } from 'react';
import api from '../../services/api';
import { useHistory } from 'react-router-dom';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import BackgroundImage from '../../assets/background.jpg'

const style = {
  root: {
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: 'cover'
  },
  loginForm: {
    display: 'flex',
    flexFlow: 'column',
    minWidth: '25%',
    border: '3px black solid',
    borderRadius: '5px',
    padding: '20px',
    backgroundColor: 'white',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px',
  },
  loginItem: {
    marginBottom: '20px'
  },
  loginTitle: {
    textAlign: 'center',
    marginBottom: '40px'
  },
  loading: {
    color: 'white',
    marginRight: '10px'
  }
};

function Login(){
  
  const history = useHistory();

  const [values, setValues] = useState({
    username: process.env.REACT_APP_MOCK_LOGIN_USER,
    password: process.env.REACT_APP_MOCK_LOGIN_PASSWORD,
    showPassword: false
  });

  const [loading, setLoading] = useState(false);

  const MySwal = withReactContent(Swal); 
  
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  async function handleSubmit(event) {   
    try{
      setLoading(true);
      const response = await api.post('/auth', {
        username: values.username,
        password: values.password
      });
  
      localStorage.setItem('jncAuthToken', response.data.token);
      history.push('/');
    }
    catch(error){
      return MySwal.fire({
        icon: 'error',
        title: <p>Não foi possível realizar o login</p>,  
        text: 'Usuário ou senha incorreto',
        showConfirmButton: true
      });
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <div style={style.root}>      
      <div style={style.loginForm}>
        <h1 style={style.loginTitle}>JNC - Portal Administrativo</h1>

        <FormControl variant="outlined" style={style.loginItem}>
          <InputLabel htmlFor="input-username">Username</InputLabel>
          <OutlinedInput 
            id="input-username" 
            label="username" 
            value={values.username}  
            onChange={handleChange('username')}
          />
        </FormControl>
        
        <FormControl variant="outlined" style={style.loginItem}>
          <InputLabel htmlFor="input-password">Password</InputLabel>
          <OutlinedInput 
            id="input-password" 
            label="password" 
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}  
            onChange={handleChange('password')}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>   
        
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {loading && <CircularProgress size={20} style={style.loading} />}
          Login
        </Button>
      </div>
      
    </div>
  );
}

export default Login;