import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Login from './pages/Login';
import Home from './pages/Home';
import Editions from './pages/Editions';
import CreateEdition from './pages/CreateEdition';
import SendNotification from './pages/SendNotification';

function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={ Login } />
        <Route exact path="/" component={ Home } />
        <Route exact path="/editions" component={ Editions } />
        <Route exact path="/createedition" component={ CreateEdition } />
        <Route exact path="/sendnotification" component={ SendNotification } />
        <Route path="*" component={ Home } />
      </Switch>
    </BrowserRouter>
  );
}

export default Routes;