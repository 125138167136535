import React, { useState } from 'react';
import Routes from '../routes';
import JncContext from '../context';

function App() {

  const [jnc, setJnc] = useState({});

  return (
    <div className="App">
      <JncContext.Provider value={{ jnc, setJnc }}>
        <Routes />
      </JncContext.Provider>
    </div>
  );
}

export default App;